.app-container {
    height: 100%;
    background-color: black;
}

.bar-container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 10%;
    height: 80%;
    /*position: relative;*/
    background-color: black;
}

.vertical-bar {
    background-color: #BD8BDD;
    display: inline-block;
    margin: 0 1px;
    /*position: absolute;*/
}

.invisible-bar {
    width: 0px;
    margin: 0 0;
}

.number-input {
    width: 15% !important;
    display: inline-block !important;
    margin: 10px;
    padding-bottom: 8px !important;
}

.sort-button {
    margin: 10px 10px;
    background-color: #BD8BDD !important;
    border-color: #BD8BDD !important;
    /*color: black !important;*/
    width: 15%;
    height: 50%;
    word-wrap: break-word;
    white-space: normal;
}

#generate-values-btn {
    width: 20%;
}

.overwrite-display-flex {
    display: block !important;
}

.logo {
    width: 20%;
}

.form-input {
    width: 20% !important;
}

#pause-btn {
    background-color: red;
    border-color: red;
}

#learn-more {
    background-color: #BD8BDD;
    border-color: #BD8BDD;
}